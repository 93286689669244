import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import styled, { css } from 'styled-components'

export const ResultsMeta = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 110px;
  padding-top: 26px;
  text-align: center;
`

export const ResultsMetaInfo = styled.div`
  color: ${COLORS.secondary};
`

export const ResultsMetaBar = styled.div`
  height: 2px;
  width: 204px;
  background-color: ${COLORS.nature.base};
  border-radius: 4px;
  margin: 20px auto 30px;
  position: relative;

  &:after {
    position: absolute;
    content: '';
    height: 2px;
    border-radius: 4px;
    left: 0;
    top: 0;
    background-color: ${COLORS.primary};
    width: ${({ width }) => (width ? `${width}%` : 0)};
  }
`
