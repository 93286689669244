import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'
import { DEVICES } from '@constants/global'

export const SortBy = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  white-space: nowrap;

  > span {
    white-space: nowrap;
  }

  > div {
    min-width: 202px;
    margin-left: 20px;
  }

  @media ${DEVICES.mobile} {
    display: none;
  }
`

export const OverviewHeading = styled.div`
  color: ${COLORS.secondary};
  font-family: ${FONTS.medium};
  margin-bottom: 32px;
  display: flex;
  align-items: flex-end;

  h1 {
    font-size: 22px;
    line-height: 30px;
    font-family: ${FONTS.medium};
    text-transform: none;
    padding-right: 32px;

    @media ${DEVICES.mobile} {
      padding-right: 0;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      ${SortBy} {
        color: ${COLORS.grey[20]};
        pointer-events: none;

        svg,
        .react-select__single-value {
          color: ${COLORS.grey[20]};
        }
      }
    `}
`
