import Select from '@global/FormInput/Select'
import { arrangeBy, soldArrangeBy, propertyCategory } from '@lib/searchFields'
import { titleCase } from '@lib/helpers'
import { NextSeo } from 'next-seo'
import { LISTING_STATUS, PROPERTY_CLASS } from '@constants/property'
import { listSearchPageType } from '@lib/variables'
import format from 'date-fns/format'
import htmr from 'htmr'

import * as S from './OverviewHeading.styled'

const buildLocationString = ({ address_region, address_suburb }) => {
  let location = ''
  if (address_region || address_suburb) {
    if (address_suburb) {
      location = address_suburb
        .split(',')
        .map((it) => titleCase(it))
        .join(', ')
    } else if (address_region) {
      location = address_region
        .split(',')
        .map((it) => titleCase(it))
        .join(', ')
    }
  }

  return location
}

const pluralize = (words, noPluralList = []) => {
  return words.map((word) => {
    // If the word is in the list of words that don't need pluralization, return it as is
    if (noPluralList.includes(word)) {
      return word
    }

    // Split the word if it contains a slash and pluralize each part
    if (word.includes('/')) {
      return word
        .split('/')
        .map((part) => pluralize([part.trim()], noPluralList)[0])
        .join('/')
    }

    // Apply the pluralization rules
    if (
      word.endsWith('s') ||
      word.endsWith('sh') ||
      word.endsWith('ch') ||
      word.endsWith('x') ||
      word.endsWith('z')
    ) {
      return `${word}es`
    } else if (
      word.endsWith('y') &&
      !['a', 'e', 'i', 'o', 'u'].includes(word[word.length - 2])
    ) {
      return `${word.substring(0, word.length - 1)}ies`
    } else {
      return `${word}s`
    }
  })
}

const getLabelsByValues = (property_class, categories) => {
  // Check if the property_class is either 'residential' or 'commercial'
  if (!['residential', 'commercial'].includes(property_class)) {
    return []
  }

  // Retrieve the options for the given property class
  const options = propertyCategory[property_class].options

  // Map the categories to their corresponding labels
  return options
    .filter((option) => option.value && categories.includes(option.value))
    .map((option) => option.label)
}

const buildSearchString = ({
  categories,
  address_region,
  address_suburb,
  listingType,
  date_from,
  date_to,
  location,
  status,
  property_class,
  isPlural,
  prefix,
  isSuburbProperties,
  offices,
}) => {
  let searchString = ''

  if (isSuburbProperties) {
    let officeText = 'Barry Plant Real Estate'
    const categoriesText = categories || 'property'
    const type = listingType.toLowerCase() === 'sale' ? 'buy' : 'rent'

    if (offices && offices.length > 0) {
      officeText = offices
        .map(
          (office) => `<a href="${office.url}">Barry Plant ${office.title}</a>`
        )
        .join(', ')
        .replace(/,([^,]*)$/, ' and$1')
    }

    return `Looking to ${type} a ${categoriesText} in ${location}?<br /> ${officeText} ${
      listingType.toLowerCase() === 'lease'
        ? 'have a range of'
        : 'sells a range of'
    } ${categoriesText} for ${
      listingType.toLowerCase() === 'lease' ? 'rent' : 'sale'
    } in ${location}.`
  }

  if (categories) {
    const noPluralList = [
      'Land',
      'Rural',
      'Acreage',
      'Offices',
      'Industrial',
      'Bulky Goods',
      'Commercial Farming',
      'Leisure',
      'Retail',
      'Consulting',
      'Medical',
      'Development',
    ]
    const labelsCategories = getLabelsByValues(property_class, categories)
    const categoriesList = isPlural
      ? pluralize(labelsCategories, noPluralList).join(', ')
      : labelsCategories.join(', ')
    searchString += `${
      status === LISTING_STATUS.SOLD
        ? ''
        : `${categoriesList} For ${listingType}`
    }`
  }

  const propertyClass = property_class === 'commercial' ? 'Commercial ' : ''

  if (status === LISTING_STATUS.SOLD) {
    if (location) {
      searchString += `${
        categories ? `${categories} ` : ''
      }Sold in ${location} by ${prefix}`
    } else {
      searchString += `${propertyClass}${categories ? `${categories} ` : ''}${
        categories ? '' : isPlural ? 'Properties' : 'Property'
      } Sold by ${prefix}`
    }
  } else {
    if (!categories) {
      if (address_region || address_suburb) {
        searchString +=
          status === LISTING_STATUS.LEASED
            ? `${prefix} Leased Properties in ${location}`
            : `${
                property_class === 'commercial'
                  ? `${prefix} Commercial ${
                      isPlural ? 'Properties' : 'Property'
                    }`
                  : `${prefix} Residential ${
                      isPlural ? 'Properties' : 'Property'
                    } For`
              } ${listingType} in ${location}`
      } else {
        searchString +=
          status === LISTING_STATUS.LEASED
            ? `${prefix} Leased Properties`
            : listingType === 'Lease'
            ? `${prefix} Rental Properties`
            : `${prefix} ${
                property_class === 'commercial' ? 'Commercial ' : ''
              }Properties For ${listingType}`
      }
    } else {
      if (location) {
        searchString += ` in ${location}`
      }
    }

    // Auction / Inspection
    if (date_from && date_to) {
      searchString = `${format(
        new Date(date_from),
        'E do MMM yyyy'
      )} - ${format(new Date(date_to), 'E do MMM yyyy')}`
    }
  }

  return searchString
}

const OverviewHeading = ({
  metaData,
  orderBy,
  onOrderChange,
  queryParams,
  pageType,
  officeAncestor,
  metaTitle,
  searchView,
  isSuburbProperties,
  offices,
}) => {
  const { listing_type, property_class } = queryParams
  let listingType = titleCase(listing_type)
  if (property_class === PROPERTY_CLASS.COMMERCIAL) {
    listingType === 'Lease'
  }

  const prefix = `Barry Plant${
    officeAncestor ? ` ${officeAncestor.title}` : ''
  }`

  const location = buildLocationString({ ...queryParams })
  const searchString = buildSearchString({
    ...queryParams,
    listingType,
    location,
    isPlural: metaData.total > 1,
    prefix,
    isSuburbProperties,
    offices,
  })

  const getH1Text = () => {
    if (metaData.total === 0) {
      return `0 results ${location ? `for "<span>${location}</span>"` : ''}`
    }

    if (pageType === listSearchPageType.SEARCH_TYPE_AUCTION) {
      return `Showing ${metaData.total} ${prefix} Upcoming Auctions`
    }

    if (pageType === listSearchPageType.SEARCH_TYPE_INSPECTION) {
      return `Showing ${metaData.total} ${prefix} Upcoming Open For Inspection Times`
    }

    if (searchString.includes('Bedroom') || isSuburbProperties) {
      return searchString
    }

    return `Showing ${metaData.total} ${searchString}`
  }

  const SEO = {
    title: getH1Text().replace(/<\/?[^>]+(>|$)/g, ''),
    openGraph: {
      title: getH1Text().replace(/<\/?[^>]+(>|$)/g, ''),
    },
  }

  return (
    <>
      {searchView === 'map' || searchView === 'draw' ? (
        <NextSeo {...SEO} noindex={true} />
      ) : queryParams.agent_slug && metaData.total === 0 ? (
        <NextSeo {...SEO} noindex={true} nofollow={true} />
      ) : (
        <NextSeo {...SEO} />
      )}
      <S.OverviewHeading disabled={metaData.total === 0}>
        <h1>{htmr(getH1Text())}</h1>
        {orderBy && pageType === listSearchPageType.SEARCH_TYPE_PROPERTY && (
          <S.SortBy>
            <span>Sort by</span>
            <Select
              defaultValue={
                (queryParams.status === LISTING_STATUS.SOLD
                  ? soldArrangeBy
                  : arrangeBy
                ).options.find((it) => it.value === orderBy) ?? null
              }
              options={
                queryParams.status === LISTING_STATUS.SOLD
                  ? soldArrangeBy.options
                  : arrangeBy.options
              }
              onChange={(option) => onOrderChange(option.value)}
            />
          </S.SortBy>
        )}
      </S.OverviewHeading>
    </>
  )
}

export default OverviewHeading
